import * as React from "react";
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const VisionAndMissionPage = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.vision}`,
    message: <FormattedMessage id="message_vision" values={commonMessageValues}/>
  }, {
    header: `${messages.mission}`,
    message: <FormattedMessage id="message_mission" values={commonMessageValues}/>
  }];

  return (<TextPage locale={intl.locale} title={`${messages.vision_and_mission}`} textBlocks={textBlocks}/>);
};

export default VisionAndMissionPage;